import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { colors, device } from '../styles/config'
import DivideSvg from '../images/divide.svg'

const Container = styled.div`
  background: ${ colors.neutralLight };
  width: 100%;
  padding-top: 7.8125rem;
  padding-bottom: 7.8125rem;
  margin-top: 7.8125rem;
  margin-bottom: 7.8125rem;
  position: relative;

  @media ${ device.sm } {
    padding-top: 4.8125rem;
    padding-bottom: 4.8125rem;
    margin-top: 4.8125rem;
    margin-bottom: 4.8125rem;

    ${ props => props.openTop && css`
      margin-top: 0;
      padding-top: 0;
      
      &:before {
        display: none;
      }
  ` }
  }

  &:before, &:after {
    content: '';
    display: block;
    position: absolute;
    top: -15px;
    width: 100%;
    height: 50px;
    background-image: url(${ DivideSvg });
    background-size: 120% auto;
    background-repeat: no-repeat;
  }

  &:after {
    bottom: -15px;
    top: initial;
    transform: rotate(180deg);
  }

  ${ props => props.openTop && css`
    margin-top: 0;
    padding-top: 0;
    
    &:before {
      display: none;
    }
  ` }
`

const Background = ({ children, openTop }) => (
  <Container openTop={openTop}>{children}</Container>
)

Background.propTypes = {
}

Background.defaultProps = {
  openTop: false
}

export default Background
