import React from 'react'
import styled from 'styled-components'

import FacebookSvg from '../images/facebook.svg'
import InstagramSvg from '../images/instagram.svg'
import TextSvg from '../images/social.svg'

import { colors, device } from '../styles/config'

const Container = styled.div`
  ${ props => props.theme.wrapper };
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 15.625rem;

  @media ${ device.sm } {
    margin-top: 9.375rem;
  }

  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

const Text = styled(TextSvg)`
  height: 2.1rem;
  margin-bottom: 1.5rem;
  cursor: pointer;

  path {
    transition: fill 0.3s;
  }

  @media ${ device.hover } {
    &:hover {
      path {
        fill: ${ colors.secondaryDarkened };
      }
    }
  }
`

const SocialRow = styled.div`
  display: grid;
  grid-auto-flow: column;
  column-gap: 2rem;
`

const Facebook = styled(FacebookSvg)`
  height: 4rem;
  margin-bottom: 0;

  circle{
    transition: fill 0.3s;
  }

  @media ${ device.hover } {
    &:hover {
      circle{
        fill: ${ colors.secondaryDarkened };
      }
    }
  }
  
`

const Instagram = styled(InstagramSvg)`
  height: 4rem;
  margin-bottom: 0;

  circle{
    transition: fill 0.3s;
  }

  @media ${ device.hover } {
    &:hover {
      circle{
        fill: ${ colors.secondaryDarkened };
      }
    }
  }
  
`

const Social = () => {
  return (
    <Container>
      <Text/>
      <SocialRow>
        <a target="blank" href="https://www.facebook.com/kulturhausinselberlin">
          <Facebook/>
        </a>
        <a target="blank" href="https://www.instagram.com/kulturhausinselberlin/">
          <Instagram/>
        </a>
      </SocialRow>
    </Container>
  )
}

export default Social
